import React from "react";
import { FaSpinner } from "react-icons/fa";
import { Trans } from "react-i18next";

const Loading = () => {
  return (
    <div className="loading-animation">
      <FaSpinner className="spinner-icon" />
      <span className="sr-only">
        <Trans>loading</Trans>
      </span>
    </div>
  );
};

export default Loading;
