export const chapterData = [
    {
        "name": "Getting started with a laboratory course",
        "uri": "getting-started-with-a-laboratory-course",
        "alias": "bgrci_dguvr_di213_026e_s2_",
        "subChapters": [
            {
                "name": "Restricted access",
                "alias": "bgrci_dguvr_di213_026e_s2_1_",
                "chapterNo": "2.1",
                "uri": "getting-started-with-a-laboratory-course/restricted-access",
                "detailText": "html/di213-026e/di213-026e_5_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_5_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.1.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.1_thumb.svg",
                "previewImgTxt": "html/di213-026ee_5_.html",
                "teaser": "Because dangers may be lurking in the lab not everybody is allowed in straight away. First ...",
                "profInfos": []
            },
            {
                "name": "Personal protection and laboratory clothing",
                "alias": "bgrci_dguvr_di213_026e_s2_2_",
                "chapterNo": "2.2",
                "uri": "getting-started-with-a-laboratory-course/personal-protection-and-laboratory-clothing",
                "detailText": "html/di213-026e/di213-026e_6_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_6_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.2_single_1.svg",
                    "images/cartoons/en/chapter-2.2_single_2.svg",
                    "images/cartoons/en/chapter-2.2_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.2_thumb.svg",
                "previewImgTxt": "html/di213-026ee_6_.html",
                "teaser": "The right protective clothing might not be as comfortable as streetwear, but it does keep may things away that may harm your health.",
                "profInfos": []
            },
            {
                "name": "Work restrictions",
                "alias": "bgrci_dguvr_di213_026e_s2_3_",
                "chapterNo": "2.3",
                "uri": "getting-started-with-a-laboratory-course/work-restrictions",
                "detailText": "html/di213-026e/di213-026e_7_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_7_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.3_single_1.svg",
                    "images/cartoons/en/chapter-2.3_single_2.svg",
                    "images/cartoons/en/chapter-2.3_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.3_thumb.svg",
                "previewImgTxt": "html/di213-026e_7_.html",
                "teaser": "Chemicals can be dangerous for certain people. They are not allowed in the lab.",
                "profInfos": []
            },
            {
                "name": "Eating, drinking, smoking, applying makeup",
                "alias": "bgrci_dguvr_di213_026e_s2_4_",
                "chapterNo": "2.4",
                "uri": "getting-started-with-a-laboratory-course/eating-drinking-smoking-applying-makeup",
                "detailText": "html/di213-026e/di213-026e_8_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_8_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.4_single_1.svg",
                    "images/cartoons/en/chapter-2.4_single_2.svg",
                    "images/cartoons/en/chapter-2.4_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.4_thumb.svg",
                "previewImgTxt": "html/di213-026e_8_.html",
                "teaser": "Food, drinks and tobacco  are not allowed in the lab. The risk of contamination is too great.",
                "profInfos": []
            },
            {
                "name": "Skin cleansing and care",
                "alias": "bgrci_dguvr_di213_026e_s2_5_",
                "chapterNo": "2.5",
                "uri": "getting-started-with-a-laboratory-course/skin-cleansing-and-care",
                "detailText": "html/di213-026e/di213-026e_9_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_9_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.5_single_1.svg",
                    "images/cartoons/en/chapter-2.5_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.5_thumb.svg",
                "previewImgTxt": "html/di213-026e_9_.html",
                "teaser": "Chemicals can harm the skin. It needs protection and care.",
                "profInfos": []
            },
            {
                "name": "Monitoring of laboratory experiments",
                "alias": "bgrci_dguvr_di213_026e_s2_6_",
                "chapterNo": "2.6",
                "uri": "getting-started-with-a-laboratory-course/monitoring-of-laboratory-experiments",
                "detailText": "html/di213-026e/di213-026e_10_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_10_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.6_single_1.svg",
                    "images/cartoons/en/chapter-2.6_single_2.svg",
                    "images/cartoons/en/chapter-2.6_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.6_thumb.svg",
                "previewImgTxt": "html/di213-026e_10_.html",
                "teaser": "Things can go wrong during an experiment. That´s why they have to be supervised constantly.",
                "profInfos": []
            },
            {
                "name": "Fire safety in the laboratory",
                "alias": "bgrci_dguvr_di213_026e_s2_7_",
                "chapterNo": "2.7",
                "uri": "getting-started-with-a-laboratory-course/fire-safety-in-the-laboratory",
                "detailText": "html/di213-026e/di213-026e_11_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_11_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.7_single_1.svg",
                    "images/cartoons/en/chapter-2.7_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.7_thumb.svg",
                "previewImgTxt": "html/di213-026e_11_.html",
                "teaser": "Because with fires the first seconds are the most crucial, everybody needs to know which extinguishing agent to use for which kind of fire.",
                "profInfos": []
            },
            {
                "name": "What to do in the event of an accident",
                "alias": "bgrci_dguvr_di213_026e_s2_8_",
                "chapterNo": "2.8",
                "uri": "getting-started-with-a-laboratory-course/what-to-do-in-the-event-of-an-accident",
                "detailText": "html/di213-026e/di213-026e_12_.html",
                "detailTextUri": "getting-started-with-a-laboratory-course/di213-026e_12_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-2.8_single_1.svg",
                    "images/cartoons/en/chapter-2.8_single_2.svg",
                    "images/cartoons/en/chapter-2.8_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-2.8_thumb.svg",
                "previewImgTxt": "html/di213-026e_12_.html",
                "teaser": "In case of an accident, keep calm, don´t endanger yourself and provide first aid.",
                "profInfos": [
                    "Verhalten im Notfall: retten, Hilfe anfordern, Erste Hilfe leisten!"
                ]
            }
        ]
    },
    {
        "name": "Practical information for working in the laboratory",
        "uri": "practical-information-for-working-in-the-laboratory",
        "alias": "bgrci_dguvr_di213_026e_s3_",
        "subChapters": [
            {
                "name": "Handling laboratory glassware",
                "alias": "bgrci_dguvr_di213_026e_s3_1_",
                "chapterNo": "3.1",
                "uri": "practical-information-for-working-in-the-laboratory/handling-laboratory-glassware",
                "detailText": "html/di213-026e/di213-026e_14_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_14_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.1_single_1.svg",
                    "images/cartoons/en/chapter-3.1_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.1_thumb.svg",
                "previewImgTxt": "html/di213-026e_14_.html",
                "teaser": "Working with glass in the lab isn´t as always as easy as it looks . The lab assistants usually have some good tips and tricks.",
                "profInfos": [
                    "Caution: burn hazard: Hot glass looks exactly the same as cold glass!"
                ]
            },
            {
                "name": "Cleaning glassware",
                "alias": "bgrci_dguvr_di213_026e_s3_2_",
                "chapterNo": "3.2",
                "uri": "practical-information-for-working-in-the-laboratory/cleaning-glassware",
                "detailText": "html/di213-026e/di213-026e_15_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_15_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.2_single_1.svg",
                    "images/cartoons/en/chapter-3.2_single_2.svg",
                    "images/cartoons/en/chapter-3.2_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.2_thumb.svg",
                "previewImgTxt": "html/di213-026e_15_.html",
                "teaser": "Cleaning glassware can be hard work, but chemicals aren’t always the solution .",
                "profInfos": []
            },
            {
                "name": "Setting up apparatuses",
                "alias": "bgrci_dguvr_di213_026e_s3_3_",
                "chapterNo": "3.3",
                "uri": "practical-information-for-working-in-the-laboratory/setting-up-apparatuses",
                "detailText": "html/di213-026e/di213-026e_16_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_16_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.3_single_1.svg",
                    "images/cartoons/en/chapter-3.3_single_2.svg",
                    "images/cartoons/en/chapter-3.3_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.3_thumb.svg",
                "previewImgTxt": "html/di213-026e_16_.html",
                "teaser": "The tension-free set-up of an apparatus isn´t as easy as it may look.",
                "profInfos": [
                    "Any form of improvisation with unsuitable components is prohibited."
                ]
            },
            {
                "name": "Heating of apparatuses",
                "alias": "bgrci_dguvr_di213_026e_s3_4_",
                "chapterNo": "3.4",
                "uri": "practical-information-for-working-in-the-laboratory/heating-of-apparatuses",
                "detailText": "html/di213-026e/di213-026e_17_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_17_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.4.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.4_thumb.svg",
                "previewImgTxt": "html/di213-026e_17_.html",
                "teaser": "The gas burner is no longer state of the art for heating an apparatus. It was replaced by heating baths and electrical heat sources.",
                "profInfos": [
                    "Basic rule: heat with care!",
                    "Never use water to extinguish fires involving silicone oil!"
                ]
            },
            {
                "name": "Working safely with condensers",
                "alias": "bgrci_dguvr_di213_026e_s3_5_",
                "chapterNo": "3.5",
                "uri": "practical-information-for-working-in-the-laboratory/working-safely-with-condensers",
                "detailText": "html/di213-026e/di213-026e_18_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_18_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.5_single_1.svg",
                    "images/cartoons/en/chapter-3.5_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.5_thumb.svg",
                "previewImgTxt": "html/di213-026e_18_.html",
                "teaser": "Cooling water always flows uphill in the lab.",
                "profInfos": []
            },
            {
                "name": "Cooling",
                "alias": "bgrci_dguvr_di213_026e_s3_6_",
                "chapterNo": "3.6",
                "uri": "practical-information-for-working-in-the-laboratory/cooling",
                "detailText": "html/di213-026e/di213-026e_19_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_19_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.6_single_1.svg",
                    "images/cartoons/en/chapter-3.6_single_2.svg",
                    "images/cartoons/en/chapter-3.6_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.6_thumb.svg",
                "previewImgTxt": "html/di213-026e_19_.html",
                "teaser": "„More is always better” is not always true for cooling.",
                "profInfos": []
            },
            {
                "name": "Tips for working with standard laboratory equipment",
                "alias": "bgrci_dguvr_di213_026e_s3_7_",
                "chapterNo": "3.7",
                "uri": "practical-information-for-working-in-the-laboratory/tips-for-working-with-standard-laboratory-equipment",
                "detailText": "html/di213-026e/di213-026e_20_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_20_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.7_single_1.svg",
                    "images/cartoons/en/chapter-3.7_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.7_thumb.svg",
                "previewImgTxt": "html/di213-026e_20_.html",
                "teaser": "The dangers of lab equipment aren´t always obvious.",
                "profInfos": []
            },
            {
                "name": "Working under vacuum conditions",
                "alias": "bgrci_dguvr_di213_026e_s3_8_",
                "chapterNo": "3.8",
                "uri": "practical-information-for-working-in-the-laboratory/working-under-vacuum-conditions",
                "detailText": "html/di213-026e/di213-026e_21_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_21_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.8_single_1.svg",
                    "images/cartoons/en/chapter-3.8_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.8_thumb.svg",
                "previewImgTxt": "html/di213-026e_21_.html",
                "teaser": "Implosions and explosions are equally bad.",
                "profInfos": [
                    "Never heat round-bottom flasks in just one place!"
                ]
            },
            {
                "name": "Working with gases",
                "alias": "bgrci_dguvr_di213_026e_s3_9_",
                "chapterNo": "3.9",
                "uri": "practical-information-for-working-in-the-laboratory/working-with-gases",
                "detailText": "html/di213-026e/di213-026e_22_.html",
                "detailTextUri": "practical-information-for-working-in-the-laboratory/di213-026e_22_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-3.9_single_1.svg",
                    "images/cartoons/en/chapter-3.9_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-3.9_thumb.svg",
                "previewImgTxt": "html/di213-026e_22_.html",
                "teaser": "Gas bottles are rockets in disguise. They´re only missing the tail unit.",
                "profInfos": []
            }
        ]
    },
    {
        "name": "Working with chemicals",
        "uri": "working-with-chemicals",
        "alias": "bgrci_dguvr_di213_026e_s4_",
        "subChapters": [
            {
                "name": "Information about substance properties and safety parameters",
                "alias": "bgrci_dguvr_di213_026e_s4_1_",
                "chapterNo": "4.1",
                "uri": "working-with-chemicals/information-about-substance-properties-and-safety-parameters",
                "detailText": "html/di213-026e/di213-026e_24_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_24_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.1_single_1.svg",
                    "images/cartoons/en/chapter-4.1_single_2.svg",
                    "images/cartoons/en/chapter-4.1_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.1_thumb.svg",
                "previewImgTxt": "html/di213-026e_24_.html",
                "teaser": "Study the labels rather than blindly trying everything.",
                "profInfos": []
            },
            {
                "name": "General safety precautions while carrying out experiments",
                "alias": "bgrci_dguvr_di213_026e_s4_2_",
                "chapterNo": "4.2",
                "uri": "working-with-chemicals/general-safety-precautions-while-carrying-out-experiments",
                "detailText": "html/di213-026e/di213-026e_25_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_25_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.2_single_1.svg",
                    "images/cartoons/en/chapter-4.2_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.2_thumb.svg",
                "previewImgTxt": "html/di213-026e_25_.html",
                "teaser": "Common sense also helps in the lab.",
                "profInfos": []
            },
            {
                "name": "Correct use of a fume hood",
                "alias": "bgrci_dguvr_di213_026e_s4_3_",
                "chapterNo": "4.3",
                "uri": "working-with-chemicals/correct-use-of-a-fume-hood",
                "detailText": "html/di213-026e/di213-026e_26_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_26_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.3_single_1.svg",
                    "images/cartoons/en/chapter-4.3_single_2.svg",
                    "images/cartoons/en/chapter-4.3_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.3_thumb.svg",
                "previewImgTxt": "html/di213-026e_26_.html",
                "teaser": "The fume cupboard protects – but only if used correctly.",
                "profInfos": []
            },
            {
                "name": "Information on experimenting with specific chemicals and substance groups",
                "alias": "bgrci_dguvr_di213_026e_s4_4_",
                "chapterNo": "4.4",
                "uri": "working-with-chemicals/information-on-experimenting-with-specific-chemicals-and-substance-groups",
                "detailText": "html/di213-026e/di213-026e_27_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_27_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.4_single_1.svg",
                    "images/cartoons/en/chapter-4.4_single_2.svg",
                    "images/cartoons/en/chapter-4.4_single_3.svg",
                    "images/cartoons/en/chapter-4.4_single_4.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.4_thumb.svg",
                "previewImgTxt": "html/di213-026e_27_.html",
                "teaser": "Some chemicals are especially reactive.",
                "profInfos": [
                    "Remember: Do like you oughta, add acid to water!"
                ]
            },
            {
                "name": "Labelling, storage and transport",
                "alias": "bgrci_dguvr_di213_026e_s4_5_",
                "chapterNo": "4.5",
                "uri": "working-with-chemicals/labelling-storage-and-transport",
                "detailText": "html/di213-026e/di213-026e_29_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_29_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.5_single_1.svg",
                    "images/cartoons/en/chapter-4.5_single_2.svg",
                    "images/cartoons/en/chapter-4.5_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.5_thumb.svg",
                "previewImgTxt": "html/di213-026e_29_.html",
                "teaser": "The motto for transporting chemicals is: Less is more.",
                "profInfos": []
            },
            {
                "name": "Bottling and transfer",
                "alias": "bgrci_dguvr_di213_026e_s4_6_",
                "chapterNo": "4.6",
                "uri": "working-with-chemicals/bottling-and-transfer",
                "detailText": "html/di213-026e/di213-026e_30_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_30_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.6_single_1.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.6_thumb.svg",
                "previewImgTxt": "html/di213-026e_30_.html",
                "teaser": "It´s all a question of technique .",
                "profInfos": []
            },
            {
                "name": "Disposal of laboratory waste",
                "alias": "bgrci_dguvr_di213_026e_s4_7_",
                "chapterNo": "4.7",
                "uri": "working-with-chemicals/disposal-of-laboratory-waste",
                "detailText": "html/di213-026e/di213-026e_31_.html",
                "detailTextUri": "working-with-chemicals/di213-026e_31_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-4.7_single_1.svg",
                    "images/cartoons/en/chapter-4.7_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-4.7_thumb.svg",
                "previewImgTxt": "html/di213-026e_31_.html",
                "teaser": "Birds of a feather flock together. Laboratory waste belongs in the right containers. Better read labels twice.",
                "profInfos": []
            }
        ]
    },
    {
        "name": "Fire and explosion hazards",
        "uri": "fire-and-explosion-hazards",
        "alias": "bgrci_dguvr_di213_026e_s5_",
        "subChapters": [
            {
                "name": "Ignitable (flammable) liquids",
                "alias": "bgrci_dguvr_di213_026e_s5_1_",
                "chapterNo": "5.1",
                "uri": "fire-and-explosion-hazards/ignitable-(flammable)-liquids",
                "detailText": "html/di213-026e/di213-026e_33_.html",
                "detailTextUri": "fire-and-explosion-hazards/di213-026e_33_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-5.1_single_1.svg",
                    "images/cartoons/en/chapter-5.1_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-5.1_thumb.svg",
                "previewImgTxt": "html/di213-026e_33_.html",
                "teaser": "Vapours are the dangerous part.",
                "profInfos": []
            },
            {
                "name": "Spontaneously ignitable substances",
                "alias": "bgrci_dguvr_di213_026e_s5_2_",
                "chapterNo": "5.2",
                "uri": "fire-and-explosion-hazards/spontaneously-ignitable-substances",
                "detailText": "html/di213-026e/di213-026e_34_.html",
                "detailTextUri": "fire-and-explosion-hazards/di213-026e_34_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-5.2_single_1.svg",
                    "images/cartoons/en/chapter-5.2_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-5.2_thumb.svg",
                "previewImgTxt": "html/di213-026e_34_.html",
                "teaser": "Flammable and sometimes even explosive.",
                "profInfos": []
            },
            {
                "name": "Explosive substances or mixtures",
                "alias": "bgrci_dguvr_di213_026e_s5_3_",
                "chapterNo": "5.3",
                "uri": "fire-and-explosion-hazards/explosive-substances-or-mixtures",
                "detailText": "html/di213-026e/di213-026e_35_.html",
                "detailTextUri": "fire-and-explosion-hazards/di213-026e_35_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-5.3_single_1.svg",
                    "images/cartoons/en/chapter-5.3_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-5.3_thumb.svg",
                "previewImgTxt": "html/di213-026e_35_.html",
                "teaser": "It all comes down to the mixture.",
                "profInfos": []
            },
            {
                "name": "Exothermic reactions",
                "alias": "bgrci_dguvr_di213_026e_s5_4_",
                "chapterNo": "5.4",
                "uri": "fire-and-explosion-hazards/exothermic-reactions",
                "detailText": "html/di213-026e/di213-026e_36_.html",
                "detailTextUri": "fire-and-explosion-hazards/di213-026e_36_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-5.4_single_1.svg",
                    "images/cartoons/en/chapter-5.4_single_2.svg",
                    "images/cartoons/en/chapter-5.4_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-5.4_thumb.svg",
                "previewImgTxt": "html/di213-026e_36_.html",
                "teaser": "1 + 1 = 3",
                "profInfos": []
            },
            {
                "name": "Decomposition reactions",
                "alias": "bgrci_dguvr_di213_026e_s5_5_",
                "chapterNo": "5.5",
                "uri": "fire-and-explosion-hazards/decomposition-reactions",
                "detailText": "html/di213-026e/di213-026e_37_.html",
                "detailTextUri": "fire-and-explosion-hazards/di213-026e_37_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-5.5_single_1.svg",
                    "images/cartoons/en/chapter-5.5_single_2.svg",
                    "images/cartoons/en/chapter-5.5_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-5.5_thumb.svg",
                "previewImgTxt": "html/di213-026e_37_.html",
                "teaser": "For the most sensitive among chemicals.",
                "profInfos": []
            }
        ]
    },
    {
        "name": "Toxicology",
        "uri": "toxicology",
        "alias": "bgrci_dguvr_di213_026e_s6_",
        "subChapters": [
            {
                "name": "Hazards of working with substances that cause adverse health effects",
                "alias": "bgrci_dguvr_di213_026e_s6_1_",
                "chapterNo": "6.1",
                "uri": "toxicology/hazards-of-working-with-substances-that-cause-adverse-health-effects",
                "detailText": "html/di213-026e/di213-026e_39_.html",
                "detailTextUri": "toxicology/di213-026e_39_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-6.1_single_1.svg",
                    "images/cartoons/en/chapter-6.1_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-6.1_thumb.svg",
                "previewImgTxt": "html/di213-026e_39_.html",
                "teaser": "Paracelsus already knew: The dose makes the poison.",
                "profInfos": []
            },
            {
                "name": "Toxicokinetics",
                "alias": "bgrci_dguvr_di213_026e_s6_2_",
                "chapterNo": "6.2",
                "uri": "toxicology/toxicokinetics",
                "detailText": "html/di213-026e/di213-026e_40_.html",
                "detailTextUri": "toxicology/di213-026e_40_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-6.2_single_1.svg",
                    "images/cartoons/en/chapter-6.2_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-6.2_thumb.svg",
                "previewImgTxt": "html/di213-026e_40_.html",
                "teaser": "Poison takes many paths.",
                "profInfos": []
            },
            {
                "name": "Mechanisms of action of carcinogenic substances",
                "alias": "bgrci_dguvr_di213_026e_s6_3_",
                "chapterNo": "6.3",
                "uri": "toxicology/mechanisms-of-action-of-carcinogenic-substances",
                "detailText": "html/di213-026e/di213-026e_45_.html",
                "detailTextUri": "toxicology/di213-026e_45_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-6.3_single_1.svg",
                    "images/cartoons/en/chapter-6.3_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-6.3_thumb.svg",
                "previewImgTxt": "html/di213-026e_45_.html",
                "teaser": "The hidden danger.",
                "profInfos": []
            },
            {
                "name": "Assessment of the hazard potential of substances that cause adverse health effects",
                "alias": "bgrci_dguvr_di213_026e_s6_4_",
                "chapterNo": "6.4",
                "uri": "toxicology/assessment-of-the-hazard-potential-of-substances-that-cause-adverse-health-effects",
                "detailText": "html/di213-026e/di213-026e_46_.html",
                "detailTextUri": "toxicology/di213-026e_46_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-6.4_single_1.svg",
                    "images/cartoons/en/chapter-6.4_single_2.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-6.4_thumb.svg",
                "previewImgTxt": "html/di213-026e_46_.html",
                "teaser": "Keep hazards in check – Exposure limits protect",
                "profInfos": []
            },
            {
                "name": "Common hazardous substances",
                "alias": "bgrci_dguvr_di213_026e_s6_5_",
                "chapterNo": "6.5",
                "uri": "toxicology/common-hazardous-substances",
                "detailText": "html/di213-026e/di213-026e_50_.html",
                "detailTextUri": "toxicology/di213-026e_50_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-6.5_single_1.svg",
                    "images/cartoons/en/chapter-6.5_single_2.svg",
                    "images/cartoons/en/chapter-6.5_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-6.5_thumb.svg",
                "previewImgTxt": "html/di213-026e_50_.html",
                "teaser": "The usual suspects - profiles at a glance.",
                "profInfos": [
                    "One litre of liquid nitrogen yields about 700 litres of nitrogen gas.",
                    "One kilogram of dry ice evaporates to form litres of carbon dioxide gas! For this reason, store dry ice only in ventilated rooms."
                ]
            },
            {
                "name": "Maternity protection and hazardous substances",
                "alias": "bgrci_dguvr_di213_026e_s6_6_",
                "chapterNo": "6.6",
                "uri": "toxicology/maternity-protection-and-hazardous-substances",
                "detailText": "html/di213-026e/di213-026e_58_.html",
                "detailTextUri": "toxicology/di213-026e_58_.html",
                "cartoonSingle": [
                    "images/cartoons/en/chapter-6.6_single_1.svg",
                    "images/cartoons/en/chapter-6.6_single_2.svg",
                    "images/cartoons/en/chapter-6.6_single_3.svg"
                ],
                "cartoonThumb": "images/cartoons/thumbs/chapter-6.6_thumb.svg",
                "previewImgTxt": "html/di213-026e_58_.html",
                "teaser": "Special situation – special protection.",
                "profInfos": []
            }
        ]
    },
    {
        "name": "Annex 1: Reference sources",
        "uri": "annex-reference-sources",
        "alias": "bgrci_dguvr_di213_026e_ag1_",
        "path": "html/di213-026e/di213-026e_59_.html"
    },
    {
        "name": "Annex 2: Legislative basis and information",
        "uri": "annex-legislative-basis-and-information",
        "alias": "bgrci_dguvr_di213_026e_ag2_",
        "path": "html/di213-026e/di213-026e_60_.html"
    },
    {
        "name": "Annex 3: Classification and labelling of chemicals",
        "uri": "annex-classification-and-labelling-of-chemicals",
        "alias": "bgrci_dguvr_di213_026e_ag3_",
        "path": "html/di213-026e/di213-026e_61_.html"
    },
    {
        "name": "Annex 4: Structure of the hazard statements and precautionary statements",
        "uri": "annex-structure-of-the-hazard-statements-and-precautionary-statements",
        "alias": "bgrci_dguvr_di213_026e_ag4_",
        "path": "html/di213-026e/di213-026e_65_.html"
    },
    {
        "name": "Annex 5: Ignitable (flammable) substances and fire classes",
        "uri": "annex-ignitable-(flammable)-substances-and-fire-classes",
        "alias": "bgrci_dguvr_di213_026e_ag5_",
        "path": "html/di213-026e/di213-026e_70_.html"
    },
    {
        "name": "Annex 6: Working with electrical systems and equipment",
        "uri": "annex-working-with-electrical-systems-and-equipment",
        "alias": "bgrci_dguvr_di213_026e_ag6_",
        "path": "html/di213-026e/di213-026e_71_.html"
    },
    {
        "name": "Annex 7: Labelling of chemical resistant gloves",
        "uri": "annex-labelling-of-chemical-resistant-gloves",
        "alias": "bgrci_dguvr_di213_026e_ag7_",
        "path": "html/di213-026e/di213-026e_72_.html"
    },
    {
        "name": "List of figures",
        "uri": "list-of-figures",
        "alias": "bgrci_dguvr_di213_026e_73_",
        "path": "html/di213-026e/di213-026e_73_.html"
    },
    {
        "name": "What you need to know in case of an emergency!",
        "uri": "what-you-need-to-know-in-case-of-an-emergency!",
        "alias": "bgrci_dguvr_di213_026e_g63_",
        "path": "html/di213-026e/di213-026e_74_.html"
    },
    {
        "name": "Imprint",
        "uri": "imprint",
        "alias": "bgrci_dguvr_di213_026e_impress_",
        "path": "html/di213-026e/di213-026e_75_.html"
    },
    {
        "name": "[Entire document DGUV Information 213-026e - Health and Safety in University Chemistry Laboratory Courses]",
        "uri": "[entire-document-dguv-information-e---health-and-safety-in-university-chemistry-laboratory-courses]",
        "alias": "bgrci_dguvr_di213_026e_0_",
        "path": "html/di213-026e/di213-026e_0_.html"
    }
]