import React from "react";
import svg from "../resources/images/notfound-img.svg";

const NotFound = () => {
  return (
    <div className="contentview-wrapper container-md my-3">
      <article className="content-sec detail-txt" id="error-page">
        <h1>Hups - 404</h1>
        <h2 id="404-heading">
          Die gewünschte Seite wurde nicht gefunden.
        </h2>
        <img
          className="404-img"
          src={svg}
          alt="404-Bild"
          style={{ maxHeight: "33vh", margin: "2rem auto" }}
        />
      </article>
    </div>
  );
};

export default NotFound;
