import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SearchButton from "./SearchButton";
import { Link } from "react-router-dom";
import { searchData_de } from "../../resources/js/searchData_de.js";
import { searchData_en } from "../../resources/js/searchData_en.js";
import { getSearchUrlParams, setSearchUrlParam } from "../../resources/js/tools";
import "./Search.sass";
import { Trans, useTranslation } from "react-i18next";
/* eslint-disable no-undef */
let elasticlunr = require("elasticlunr");
require("../../resources/js/lunr.stemmer.support.js")(elasticlunr);
require("../../resources/js/lunr.de.js")(elasticlunr);
/* eslint-enable no-undef */

export default function Search({
  getUrl = false,
  lng = "de",
  resCountDefault = 100,
  tabIndex = 0,
  setSidebar = false,
}) {
  const [searchData, setSearchData] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const urlParams = getSearchUrlParams();
    if (getUrl && urlParams !== "") {
      if (searchPhrase === "") {
        setSearchPhrase(getSearchUrlParams());
      }
    }
  }, [searchPhrase, getUrl]);

  const buildIndex_de = () => {
    // build elasticlunr index
    const index = elasticlunr(function () {
      this.use(elasticlunr.de); // use german stemmer if language = de
      this.addField("fullName");
      this.addField("name");
      this.addField("chapter");
      this.addField("teaser");
      this.addField("text");
      this.addField("uri");
      this.setRef("id");
      // this.saveDocument(false);
    });
    elasticlunr.clearStopWords();

    for (let i = 0; i < searchData_de.length; i++) {
      let element = searchData_de[i];
      element["id"] = i;
      index.addDoc(element);
    }

    return index;
  };

  const buildIndex_en = () => {
    // build elasticlunr index
    const index = elasticlunr();

    // Define the fields and reference for the index
    index.addField("fullName");
    index.addField("name");
    index.addField("chapter");
    index.addField("teaser");
    index.addField("text");
    index.addField("uri");
    index.setRef("id");

    // Optional: Clear stop words if needed
    elasticlunr.clearStopWords();

    // Add documents to the index
    for (let i = 0; i < searchData_en.length; i++) {
      let element = searchData_en[i];
      element["id"] = i;
      index.addDoc(element);
    }

    return index;
  };

  const index_de = buildIndex_de();
  const index_en = buildIndex_en();

  // Load chapterdata of correct language
  useEffect(() => {
    setSearchPhrase("");
    setSearchResults([]);
    let searchDataToUse = searchData_de;
    if (lng === "en") searchDataToUse = searchData_en;
    setSearchData(searchDataToUse);
  }, [lng]);

  const resCount = resCountDefault !== undefined ? resCountDefault : 100;

  useEffect(() => {
    if (searchData) {
      if (searchPhrase.length >= 3) {
        if (lng === "de") {
          setSearchResults(searchFor(searchPhrase, index_de));
        } else if (lng === "en") {
          setSearchResults(searchFor(searchPhrase, index_en));
        }
      }
      setSearchUrlParam(searchPhrase);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase]);

  return (
    <div className="search">
      <div className="search-bar-wrapper">
        <div className="input-wrapper">
          <input
            className="searchbar"
            id="searchbar-input"
            type="search"
            placeholder={t("suchen")}
            value={searchPhrase}
            aria-label={t("suche")}
            tabIndex={tabIndex}
            onChange={(e) => {
              setSearchPhrase(e.target.value);
              if (e.target.value.length < 3) {
                setSearchUrlParam("");
                setSearchResults([]);
              }
            }}
          />
        </div>
        <SearchButton color="#fff" tabIndex={tabIndex} />
      </div>
      {searchResults && searchResults.length > 0 ? (
        <div className="search-results-wrapper">
          <h2 className="search-result-title">
            <Trans>such</Trans>
          </h2>
          <p>
            <b>
              {searchResults.length} <Trans>ergebniss</Trans>
            </b>
            <Trans>fuer</Trans>
          </p>
          <div className="search-results-text">
            {searchResults.map((result, index) => {
              if (index < resCount) {
                return (
                  <div key={index} className="search-result">
                    <Link
                      to={"/" + result.uri}
                      className="search-result-heading"
                      dangerouslySetInnerHTML={{ __html: result.name }}
                    />
                    <p
                      className="search-result-text"
                      dangerouslySetInnerHTML={{ __html: result.text }}
                    />
                  </div>
                );
              }
              return null;
            })}
            {searchResults.length > resCount && (
              <Link
                to={
                  getSearchUrlParams() !== ""
                    ? "/suche?search=" + getSearchUrlParams()
                    : "/suche"
                }
                className="more-results-btn btn"
                onClick={() => {
                  setSearchPhrase("");
                  setSearchResults([]);
                  setSidebar(false);
                }}
              >
                <Trans>more_results</Trans>
              </Link>
            )}
          </div>
        </div>
      ) : (
        (searchPhrase.length > 0 || !resCountDefault) && (
          <div className="search-results-wrapper">
            <h2 className="search-result-title">
              <Trans>such</Trans>
            </h2>
            <p>
              <Trans>kein_ergeb</Trans>
            </p>
            {searchPhrase.length < 3 ? (
              <p>
                <Trans>mehr_als</Trans>{" "}
                <b>
                  <Trans>drei</Trans>
                </b>
                <Trans>zu_suchen</Trans>
              </p>
            ) : (
              <p>
                <Trans>not_found</Trans>
              </p>
            )}
          </div>
        )
      )}
    </div>
  );
}

/**
 * search function: initiates elasticlunr search
 * builds index
 * adds json documents to index from "searchData.js" file
 * settings to boost different data points in search
 * @param {any} value search phrase
 * @returns {any} json object as search result(s)
 */
function searchFor(value, index) {
  try {
    let searchRes = index.search(value, {
      fields: {
        name: { boost: 5 },
        chapter: { boost: 1 },
        text: { boost: 1 },
        uri: { boost: 3 },
        teaser: { boost: 3 },
      },
      expand: true,
    });
    searchRes.sort(function (a, b) {
      let keyA = a.score,
        keyB = b.score;
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    let results = [];

    for (let i = 0; i < searchRes.length; i++) {
      let result = searchRes[i];
      let doc = index.documentStore.getDoc(result.ref);
      let resName = highlightSearchPhrase(value, doc.name);
      let resUri = doc.uri;
      let text = doc.text;
      let searchIndexOfText = text.toLowerCase().indexOf(value);
      let resText = highlightSearchPhrase(
        value,
        searchIndexOfText >= 0
          ? "... " + text.substr(searchIndexOfText, 200) + " ..."
          : text.substr(0, 200) + " ...",
      );
      let resScore = result.score;
      let data = { name: resName, uri: resUri, text: resText, score: resScore };
      results.push(data);
    }

    return results;
  } catch (error) {
    console.error(error);
  }
}

/**
 * rewrites all words in search result texts, which are representing the search phrase into bold style
 * @param {any} searchPhrase
 */
function highlightSearchPhrase(searchPhrase, text) {
  let searchPhraseUpperCase =
    searchPhrase.charAt(0).toUpperCase() + searchPhrase.slice(1);
  return text
    .split(searchPhrase)
    .join("<b>" + searchPhrase + "</b>")
    .split(searchPhraseUpperCase)
    .join("<b>" + searchPhraseUpperCase + "</b>");
}

Search.propTypes = {
  getUrl: PropTypes.bool,
  lng: PropTypes.string,
  resCountDefault: PropTypes.number,
  tabIndex: PropTypes.number,
  setSidebar: PropTypes.func,
  resetSearch: PropTypes.string,
};
