import PropTypes from "prop-types";

export const chapterPropTypes = PropTypes.shape({
  name: PropTypes.string,
  uri: PropTypes.string,
  alias: PropTypes.string,
  subChapters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      alias: PropTypes.string,
      chapterNo: PropTypes.string,
      uri: PropTypes.string,
      detailText: PropTypes.string,
      detailTextUri: PropTypes.string,
      cartoonSingle: PropTypes.arrayOf(PropTypes.string),
      cartoonThumb: PropTypes.string,
      previewImgTxt: PropTypes.string,
      teaser: PropTypes.string,
      abstract: PropTypes.string,
      profInfos: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
});
