import React from "react";
import "./DguvLogo.sass";
import { useTranslation } from "react-i18next";

const DguvLogo = () => {
  const { t } = useTranslation();
  return (
    <div className="logo-btn-wrapper">
      <a
        className="dguv-logo navbar-btn"
        href={t("dguv_url")}
        target="_blank"
        rel="noreferrer"
        title={t("to_dguv")}
      >
        <img
          className=""
          src={require("../../resources/images/logo_dguv.png")}
          alt="DGUV Logo"
          width={301}
          height={132}
        />
      </a>
    </div>
  );
};

export default DguvLogo;
