import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Search from "../components/ui-components/Search";
import { Trans, useTranslation } from "react-i18next";

export default function Suche() {
  // eslint-disable-next-line no-undef
  require("./Pages.sass");
  const { i18n } = useTranslation();

  return (
    <div className="contentview-wrapper txt-only">
      <div className="content-top-wrapper">
        <Container fluid="md">
          <h1 className="searchview-heading content-top-heading">
            <Trans>suche</Trans>
          </h1>
        </Container>
      </div>
      <Container fluid="md">
        <section className="content-sec" id="search">
          <Search getUrl={true} lng={i18n.language} />
        </section>
      </Container>
    </div>
  );
}

Suche.propTypes = {
  lng: PropTypes.string,
};
