import React, { useState } from "react";
import { IoGlobe } from "react-icons/io5";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function LangSwitcher() {
  const [menu, setMenu] = useState(false);
  const { i18n } = useTranslation();
  const languages = Object.keys(i18n.options.resources);

  // function toggleLanguage(e) {
  //   setMenu(false);
  //   let newLng = e.target.value;
  //   i18next.changeLanguage(newLng);
  // }

  function changeLanguage(lng) {
    setMenu(false);
    i18next.changeLanguage(lng);
  }

  return (
    <div className="d-flex me-4 position-relative">
      <button className="navbar-btn" onClick={() => setMenu(!menu)}>
        <IoGlobe />
      </button>
      {menu && (
        <div className="position-absolute top-100 rounded shadow bg-light d-flex flex-column w-100 p-1 z-3">
          {languages.map((item, index) => {
            return (
              <button
                key={item}
                className={`btn p-0 ${
                  item === i18n.language ? "btn-primary" : "btn-light"
                } ${index === 0 && "mb-1"}`}
                value={item}
                onClick={() => changeLanguage(item)}
              >
                {item.toLocaleUpperCase()}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
