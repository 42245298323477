import React from "react";
import PropTypes from "prop-types";
import { iconsLib as iconPath } from "../../resources/images/dguv-icons/iconsLib";

const Icon = ({ size, color, icon, className, viewBox }) => {
  return (
    <svg
      className={className}
      viewBox={viewBox}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={color} d={iconPath[icon]} />
    </svg>
  );
};

Icon.default = {
  size: 16,
  color: "#000000",
  viewBox: "0 0 24 24",
  className: "",
};

Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
