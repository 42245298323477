import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { HiOutlineMenu, HiShare } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

import { Container } from "react-bootstrap";
import Icon from "./ui-components/DguvIcons";
import "./Navbar.sass";

import SearchButton from "./ui-components/SearchButton";
import NavOverlay from "./ui-components/NavOverlay";
import DguvLogo from "./ui-components/DguvLogo.js";
import { Trans, useTranslation } from "react-i18next";
import LangSwitcher from "./ui-components/LangSwitcher.js";
import { chapterPropTypes } from "../utils/types";

export default function Navbar({ chapterData = [] }) {
  let currPath = useLocation().pathname;
  const [sidebar, setSidebar] = useState(false);
  const chapters = chapterData;

  const { t } = useTranslation();

  if (sidebar && window.innerWidth < 1400) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }

  // hide sidebar if esc-key is pressed
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setSidebar(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <div id="navbar">
      <Container fluid="lg" className="navbar-wrapper">
        <DguvLogo />
        <div className="nav-btn-wrapper">
          <button
            className="navbar-btn"
            title={t("seite_teilen")}
            onClick={shareSite}
          >
            <HiShare />
            <span className="sr-only">
              <Trans>seite_teilen</Trans>{" "}
            </span>
          </button>
          <SearchButton className="navbar-btn"></SearchButton>
          {currPath !== "/" && currPath !== "/home" && (
            <Link to="/" className="navbar-btn">
              <Icon icon="home" viewBox="0 0 124 124" size={32} />
              <span className="sr-only">
                <Trans>zur_start_seite</Trans>
              </span>
            </Link>
          )}
          <LangSwitcher />
          <button className="navbar-btn" onClick={() => setSidebar(!sidebar)}>
            <HiOutlineMenu />
            <span className="sr-only">
              <Trans>menue_oeffnen</Trans>
            </span>
          </button>
        </div>
      </Container>
      <NavOverlay
        active={sidebar}
        setSidebar={setSidebar}
        currPath={currPath}
        chapterData={chapters}
      />
    </div>
  );
}

function shareSite() {
  if (navigator.share) {
    navigator.share({
      title: "Laborstart",
      text: <Trans>infos_sicheheit</Trans>,
      url: "https://laborstart.bgrci.de",
    });
  } else {
    if (navigator.clipboard) {
      navigator.clipboard.writeText("https://laborstart.bgrci.de");
    } else {
      let text = "https://laborstart.bgrci.de";
      let textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        let successful = document.execCommand("copy");
        let msg = successful ? "successful" : "unsuccessful";
        // eslint-disable-next-line no-console
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
    tempAlert("Copied to clipboard");
  }
}

function tempAlert() {
  let element = document.createElement("div");
  element.classList.add("popup-overlay");
  let icon = document.createElement("i");
  icon.classList.add("fas", "fa-copy");
  // icon.onclick = (fade(element));
  icon.setAttribute(
    "onClick",
    "document.querySelector('.popup-overlay').remove()",
  );
  element.innerHTML = <Trans>link_kopiert</Trans>;
  element.append(icon);
  document.querySelector(".App").append(element);
  window.setTimeout(function () {
    fade(document.querySelector(".popup-overlay"));
  }, 2000);
}

function fade(element) {
  let op = 1; // initial opacity
  let timer = setInterval(function () {
    if (op <= 0.1) {
      clearInterval(timer);
      element.style.display = "none";
    }
    element.style.opacity = op;
    element.style.filter = "alpha(opacity=" + op * 100 + ")";
    op -= op * 0.1;
  }, 50);
}

Navbar.propTypes = {
  chapterData: PropTypes.arrayOf(chapterPropTypes),
};
