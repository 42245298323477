export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function modulo(n, m) {
  let remain = n % m;
  return Math.floor(remain >= 0 ? remain : remain + m);
}

export function setUrlParam(para, value) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(para, value);
  let splitUrl = window.location.href.split("/");
  let pageName = splitUrl[splitUrl.length - 1].split("?")[0];
  window.history.pushState(null, null, pageName + "?" + urlParams);
}

/**
 * gets the current search parameter
 */
export function getUrlParams(para) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(para) !== null &&
    urlParams.get(para).length !== 0
    ? urlParams.get(para)
    : "";
}

/**
 * sets a parameter to the url as "?search="
 * @param {any} value: search value
 */
export function setSearchUrlParam(value) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set("search", value);
  let splitUrl = window.location.href.split("/");
  let pageName = splitUrl[splitUrl.length - 1].split("?")[0];
  window.history.pushState(null, null, pageName + "?" + urlParams);
}

/**
 * gets the current search parameter
 */
export function getSearchUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("search") !== null &&
    urlParams.get("search").length !== 0
    ? urlParams.get("search")
    : "";
}
