import React, { lazy, Suspense, useState, useEffect } from "react";
import "./resources/css/App.sass";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { chapterData as chaptersDE } from "./data/chapterData_de.js";
import { chapterData as chaptersEN } from "./data/chapterData_en.js";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Suche from "./pages/Suche";
import "./resources/css/ImportBootstrap.scss";
import Loading from "./components/ui-components/Loading";
import NotFound from "./pages/NotFound";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { getUrlParams } from "./resources/js/tools";

export default function App() {
  const isEdge = window.navigator.userAgent.indexOf("Edge") !== -1;
  const isIE = window.navigator.userAgent.indexOf("Trident") !== -1 && !isEdge;
  const [chapters, setChapters] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const lng = navigator.language;
    i18next.changeLanguage(lng);

    // Change language if "lng"-parameter exists
    const urlLng = getUrlParams("lng")
    if (i18n.options.supportedLngs.includes(urlLng)) {
      i18next.changeLanguage(urlLng);
    }

    // init matomo
    if (!window._paq) {
      let _paq = [];
      let u = "https://matomo.jedermann.de/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", "85"]);
      _paq.push(["disableCookies"]);
      let d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
      window._paq = _paq;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load chapterdata of correct language
  useEffect(() => {
    if (i18n.language) {
      if (i18n.language === "de" || i18n.language === "de-DE") setChapters(chaptersDE);
      else setChapters(chaptersEN);
    }
  }, [i18n.language]);

  // set document title and trigger matomo
  useEffect(() => {
    if (chapters) {
      const getTitle = () => {
        let title = null;
        for (const chapter of chapters) {
          if (chapter?.subChapters) {
            for (const subChapter of chapter.subChapters) {
              if (subChapter?.uri && (`/${subChapter.uri}` === pathname || `/chapter-${subChapter.chapterNo}` === pathname)) {
                title = subChapter.name;
                break;
              }
            }
          }
          if (title) break;
        }
        return title;
      };
      const title = getTitle();
      if (title) {
        document.title = `${i18n.language === "de" ? "LaborStart" : "LabStart"} | ${title}`;
      } else {
        document.title = t("app_full_title");
      };
    }

    // tracking with matomo
    /* eslint-disable no-undef */
    if (window._paq) {
      _paq.push([
        "setCustomUrl",
        encodeURI(
          typeof window === "undefined" ? pathname : window.location.href,
        ),
      ]);
      _paq.push(["setDocumentTitle", document.title]);
      _paq.push(["trackPageView"]);
    }
    /* eslint-enable no-undef */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, chapters]);

  if (isIE) {
    return (
      <div className="App">
        <Navbar chapterData={chapters} />
        <div id="header-row">
          <Container fluid="lg">
            <Row>
              <Col>
                <p>
                  <Trans>
                    LaborStart | Sicherheit und Gesundheit im chemischen Praktikum
                  </Trans>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Routes>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    );
  } else {
    const Home = lazy(() => import("./pages/Home"));
    const Overview = lazy(() => import("./pages/Overview"));
    const ContentView = lazy(() => import("./pages/Contentview"));
    const Literatur = lazy(() => import("./pages/Literatur"));
    const Datenschutz = lazy(() => import("./pages/Datenschutz"));
    const Barrierefreiheit = lazy(() => import("./pages/Barrierefreiheit"));
    const Intro = lazy(() => import("./pages/Intro"));
    const Impressum = lazy(() => import("./pages/Impressum"));
    const Anhang = lazy(() => import("./pages/Anhang"));

    return (
      <div className="App">
        <header>
          <Navbar chapterData={chapters} />
          <div id="header-row">
            <Container fluid="lg">
              <Row>
                <Col>
                  <p>
                    <Trans>app_full_title</Trans>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </header>
        <main id="content-wrapper">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/uebersicht"
                element={<Overview chapterData={chapters} />}
              />
              <Route
                path="/overview"
                element={<Overview chapterData={chapters} />}
              />
              <Route
                path="/%C3%BCbersicht"
                element={<Overview chapterData={chapters} />}
              />
              <Route
                path="/übersicht"
                element={<Overview chapterData={chapters} />}
              />

              {/* create routes for all contentviews (chapterNo) */}
              {chaptersDE.map((chapter) => {
                return (
                  chapter.subChapters &&
                  chapter.subChapters.map((subChapter) => {
                    return (
                      <Route
                        key={subChapter.chapterNo}
                        path={"chapter-" + subChapter.chapterNo}
                        element={
                          <ContentView
                            chapterNo={subChapter.chapterNo}
                            chapterData={chapters}
                          />
                        }
                      />
                    );
                  })
                );
              })}

              {/* create routes for all german uris */}
              {chaptersDE.map((chapter) => {
                return (
                  chapter.subChapters &&
                  chapter.subChapters.map((subChapter) => {
                    return (
                      <Route
                        key={subChapter.chapterNo}
                        path={subChapter.uri}
                        element={
                          <ContentView
                            chapterNo={subChapter.chapterNo}
                            chapterData={chapters}
                          />
                        }
                      />
                    );
                  })
                );
              })}

              {/* create routes for all english uris */}
              {chaptersEN.map((chapter) => {
                return (
                  chapter.subChapters &&
                  chapter.subChapters.map((subChapter) => {
                    return (
                      <Route
                        key={subChapter.chapterNo}
                        path={subChapter.uri}
                        element={
                          <ContentView
                            chapterNo={subChapter.chapterNo}
                            chapterData={chapters}
                          />
                        }
                      />
                    );
                  })
                );
              })}

              <Route path="/anhang" element={<Anhang />} />
              <Route path="/appendix" element={<Anhang />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/intro" element={<Intro />} />
              <Route path="/barrierefreiheit" element={<Barrierefreiheit />} />
              <Route path="/accessibility" element={<Barrierefreiheit />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/privacy" element={<Datenschutz />} />
              <Route path="/literatur" element={<Literatur />} />
              <Route path="/literature" element={<Literatur />} />
              <Route
                exact
                path="suche"
                element={<Suche lng={i18n.language} />}
              />
              <Route
                exact
                path="search"
                element={<Suche lng={i18n.language} />}
              />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    );
  }
}
