import React from "react";
import PropTypes from "prop-types";
import Icon from "./DguvIcons";
import { Link } from "react-router-dom";
import { getUrlParams } from "../../resources/js/tools";
import "./SearchButton.sass";
import { Trans } from "react-i18next";

export default function SearchButton({ className = "", tabIndex = 0 }) {
  const urlParams = getUrlParams();
  return (
    <Link
      to={urlParams !== "" ? "/suche?search=" + getUrlParams() : "/suche"}
      className={className !== "" ? className + " search-btn" : "search-btn"}
      tabIndex={tabIndex}
    >
      <Icon icon="search" viewBox="0 0 124 124" size={32} />
      <span className="sr-only sr-only-light">
        <Trans>suche</Trans>{" "}
      </span>
    </Link>
  );
}

SearchButton.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
};
