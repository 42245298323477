import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
// import { chapterData as Chapters } from '../../data/chapterData.js';
import CloseButton from "./CloseButton";
import Icon from "./DguvIcons";
import Search from "./Search";
import "./NavOverlay.sass";
import Loading from "./Loading.js";
import { chapterPropTypes } from "../../utils/types";

export default function NavOverlay({
  chapterData = [],
  active = false,
  setSidebar = undefined,
  currPath = "",
}) {
  const { t } = useTranslation();
  const chapters = chapterData;
  const [activeChapter, setActiveChapter] = useState("");

  const { i18n } = useTranslation();

  return (
    <div
      className={"overlay p-3" + (active ? " active" : "")}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          // focus left self
          setSidebar(false);
        }
      }}
      tabIndex={-1}
      aria-hidden={active ? "false" : "true"}
      style={{ outline: "none" }}
    >
      <div className="overlay-wrapper">
        <nav className="overlay-nav" aria-label={t("nav_overlay")}>
          <div className="overlay-nav-btn-wrapper">
            <Link
              to="/"
              className="home-btn"
              tabIndex={active ? 0 : -1}
              title={t("zur_start_seite")}
            >
              <Icon icon="home" viewBox="0 0 124 124" size={32} />
            </Link>
            <Link
              to="/uebersicht"
              className="overview-btn"
              tabIndex={active ? 0 : -1}
              onClick={() => setSidebar(false)}
              title={t("themen_bereich")}
            >
              <BsGrid3X3GapFill size={24} />
            </Link>
          </div>
          <CloseButton
            action={() => setSidebar(false)}
            tabIndex={active ? 0 : -1}
          ></CloseButton>
        </nav>
        {currPath !== "/suche" && (
          <Search
            lng={i18n.language}
            resCount={3}
            setSidebar={setSidebar}
            tabIndex={active ? 0 : -1}
          />
        )}
        <div className="menu-links-wrapper">
          <nav className="menu-links" aria-label=<Trans>themen</Trans>>
            {chapters ? (
              chapters.map((chapter, index) => {
                if (chapter.subChapters !== undefined) {
                  return (
                    <div key={index} className="chapter">
                      <button
                        className={
                          activeChapter === chapter.name && active
                            ? "main-chapter-link menu-chapter-heading chapter-overview clicked"
                            : "main-chapter-link menu-chapter-heading chapter-overview"
                        }
                        onClick={() => {
                          activeChapter === chapter.name
                            ? setActiveChapter("")
                            : setActiveChapter(chapter.name);
                        }}
                        // onFocus={()=>setActiveChapter("")}
                        tabIndex={active ? 0 : -1}
                      >
                        <span>{chapter.name}</span>
                        <MdKeyboardArrowRight
                          size={48}
                          className="arrow-left-lightblue-icon"
                          aria-hidden="true"
                        />
                      </button>
                      <nav
                        className={
                          activeChapter === chapter.name && active
                            ? "subChapterOverview active"
                            : "subChapterOverview"
                        }
                        aria-label={t("nav_topic") + chapter.name}
                      >
                        <div className="subChapter-heading-wrapper">
                          <button
                            className="backToOverview"
                            onClick={() => setActiveChapter("")}
                            title="zurück"
                            tabIndex={
                              activeChapter === chapter.name && active ? 0 : -1
                            }
                          >
                            <MdKeyboardArrowLeft
                              size={48}
                              className="arrow-left-lightblue-icon"
                              aria-hidden="true"
                            />
                            <h2 className="subChapter-heading">
                              {chapter.name}
                            </h2>
                          </button>
                        </div>
                        {chapter.subChapters.map((subChapter, subIndex) => {
                          return (
                            <Link
                              key={subIndex}
                              className="menu-link"
                              to={"" + subChapter.uri}
                              tabIndex={
                                activeChapter === chapter.name && active
                                  ? 0
                                  : -1
                              }
                              onClick={() => setSidebar(false)}
                            >
                              {subChapter.name}
                            </Link>
                          );
                        })}
                      </nav>
                    </div>
                  );
                } else return null;
              })
            ) : (
              <Loading />
            )}
          </nav>
          <nav className="menu-bottom-links" aria-label=<Trans>nav</Trans>>
            <Link
              className="menu-bottom-link"
              to="intro"
              tabIndex={active ? 0 : -1}
              onClick={() => setSidebar(false)}
            >
              <Trans>nav_intro</Trans>
            </Link>
            <Link
              className="menu-bottom-link"
              to="literatur"
              tabIndex={active ? 0 : -1}
              onClick={() => setSidebar(false)}
            >
              <Trans>nav_literature</Trans>
            </Link>
            <Link
              className="menu-bottom-link"
              to="datenschutz"
              tabIndex={active ? 0 : -1}
              onClick={() => setSidebar(false)}
            >
              <Trans>nav_privacy</Trans>
            </Link>
            <Link
              className="menu-bottom-link"
              to="barrierefreiheit"
              tabIndex={active ? 0 : -1}
              onClick={() => setSidebar(false)}
            >
              <Trans>nav_accessibility</Trans>
            </Link>
            <Link
              className="menu-bottom-link"
              to="impressum"
              tabIndex={active ? 0 : -1}
              onClick={() => setSidebar(false)}
            >
              <Trans>impressum</Trans>
            </Link>
          </nav>
        </div>
      </div>
      <div className="menu-background">
        <img
          className="menu-background-img"
          // eslint-disable-next-line no-undef
          src={require("../../resources/images/characters/charly.png")}
          width={350}
          height={600}
          alt=""
        />
      </div>
    </div>
  );
}

NavOverlay.propTypes = {
  chapterData: PropTypes.arrayOf(chapterPropTypes),
  active: PropTypes.bool,
  setSidebar: PropTypes.func,
  currPath: PropTypes.string,
};
