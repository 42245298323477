export const chapterData = [{
    "name": "Praktikumsbeginn",
    "uri": "praktikumsbeginn",
    "alias": "bgrci_dguvi_di213_026_s2_",
    "subChapters": [{
        "name": "Zugangsberechtigung",
        "alias": "bgrci_dguvi_di213_026_s2_1_",
        "chapterNo": "2.1",
        "uri": "praktikumsbeginn/zugangsberechtigung",
        "detailText": "html/di213-026/di213-026_5_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_5_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.1.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.1_thumb.svg",
        "previewImgTxt": "html/di213-026_5_.html",
        "teaser": "Weil im Labor Gefahren lauern können darf nicht jeder gleich hinein. Erst muss …",
        "abstract": "Nur unterwiesene Personen dürfen in einem Labor tätig werden. Da in einem Labor mit Gefahrstoffen gearbeitet wird, ist der Zugang nur für die Praktikumsteilnehmenden und die Betreuenden gestattet. Unbefugte haben keinen Zutritt.",
        "profInfos": []
    }, {
        "name": "Körperschutz und Arbeitskleidung",
        "alias": "bgrci_dguvi_di213_026_s2_2_",
        "chapterNo": "2.2",
        "uri": "praktikumsbeginn/koerperschutz-und-arbeitskleidung",
        "detailText": "html/di213-026/di213-026_6_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_6_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.2_single_1.svg", "images/cartoons/de/chapter-2.2_single_2.svg", "images/cartoons/de/chapter-2.2_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.2_thumb.svg",
        "previewImgTxt": "html/di213-026_6_.html",
        "teaser": "Die richtige Schutzkleidung ist vielleicht unbequemer als Freizeitkleidung, aber sie hält vieles ab, was der Gesundheit schaden könnte. ",
        "abstract": "Während des Aufenthalts im chemischen Laboratorium muss eine Schutzbrille mit Seitenschutz und Augenbrauenabdeckung getragen werden.",
        "profInfos": []
    }, {
        "name": "Beschäftigungsbeschränkungen",
        "alias": "bgrci_dguvi_di213_026_s2_3_",
        "chapterNo": "2.3",
        "uri": "praktikumsbeginn/beschaeftigungsbeschraenkungen",
        "detailText": "html/di213-026/di213-026_7_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_7_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.3_single_1.svg", "images/cartoons/de/chapter-2.3_single_2.svg", "images/cartoons/de/chapter-2.3_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.3_thumb.svg",
        "previewImgTxt": "html/di213-026_7_.html",
        "teaser": "Chemikalien können für bestimmte Personen gefährlich sein. Diese Personen dürfen nicht ins Labor.",
        "abstract": "Arbeiten unter dem Einfluss von Alkohol, bewusstseinstrübenden Medikamenten und Drogen sind nicht gestattet. Bestimmte Chemikalien haben sensibilisierende Eigenschaften und können Allergien auslösen. Personen mit bestehenden Allergien gegenüber bestimmter Chemikalien sollten vor dem Praktikum ihre für das Praktikum verantwortliche Person informieren.",
        "profInfos": []
    }, {
        "name": "Essen, Trinken, Rauchen, Schminken",
        "alias": "bgrci_dguvi_di213_026_s2_4_",
        "chapterNo": "2.4",
        "uri": "praktikumsbeginn/essen-trinken-rauchen-schminken",
        "detailText": "html/di213-026/di213-026_8_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_8_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.4_single_1.svg", "images/cartoons/de/chapter-2.4_single_2.svg", "images/cartoons/de/chapter-2.4_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.4_thumb.svg",
        "previewImgTxt": "html/di213-026_8_.html",
        "teaser": "Speisen, Getränke, Genussmittel, Tabakwaren dürfen nicht ins Labor. Die Kontaminationsgefahr ist zu groß.",
        "abstract": "Speisen, Genussmittel, Tabakwaren, Kosmetika und Getränke dürfen wegen der Gefahr der Kontamination im Labor weder aufbewahrt noch dort zu sich genommen werden.",
        "profInfos": []
    }, {
        "name": "Hautreinigung und Hautpflege",
        "alias": "bgrci_dguvi_di213_026_s2_5_",
        "chapterNo": "2.5",
        "uri": "praktikumsbeginn/hautreinigung-und-hautpflege",
        "detailText": "html/di213-026/di213-026_9_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_9_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.5_single_1.svg", "images/cartoons/de/chapter-2.5_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.5_thumb.svg",
        "previewImgTxt": "html/di213-026_9_.html",
        "teaser": "Chemikalien können die Haut angreifen. Deshalb braucht sie Hautschutz und Pflege.",
        "abstract": "Vor Pausen und nach Beendigung der Laborarbeit sind die Hände stets gründlich mit Wasser und Haut schonenden Hautreinigungsmitteln zu waschen.",
        "profInfos": []
    }, {
        "name": "Überwachung von Laborversuchen",
        "alias": "bgrci_dguvi_di213_026_s2_6_",
        "chapterNo": "2.6",
        "uri": "praktikumsbeginn/ueberwachung-von-laborversuchen",
        "detailText": "html/di213-026/di213-026_10_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_10_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.6_single_1.svg", "images/cartoons/de/chapter-2.6_single_2.svg", "images/cartoons/de/chapter-2.6_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.6_thumb.svg",
        "previewImgTxt": "html/di213-026_10_.html",
        "teaser": "Auch während eines Versuches kann etwas schief gehen. Deshalb müssen Laborversuche ständig überwacht werden.",
        "abstract": "Wer einen Versuch durchführt, darf den Arbeitsplatz nur dann verlassen wenn ...",
        "profInfos": []
    }, {
        "name": "Brandschutz in Laboratorien",
        "alias": "bgrci_dguvi_di213_026_s2_7_",
        "chapterNo": "2.7",
        "uri": "praktikumsbeginn/brandschutz-in-laboratorien",
        "detailText": "html/di213-026/di213-026_11_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_11_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.7_single_1.svg", "images/cartoons/de/chapter-2.7_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.7_thumb.svg",
        "previewImgTxt": "html/di213-026_11_.html",
        "teaser": "Weil es bei Bränden auf die ersten Sekunden ankommt, muss jeder wissen, mit welchen Löschmitteln welche Brände gelöscht werden können.",
        "abstract": "Über Art, Aufstellungsort und richtige Handhabung der vorhandenen Löschmittel muss sich jeder in einem Labor Arbeitende vor Beginn seiner Tätigkeit informieren.",
        "profInfos": []
    }, {
        "name": "Maßnahmen bei Unfällen",
        "alias": "bgrci_dguvi_di213_026_s2_8_",
        "chapterNo": "2.8",
        "uri": "praktikumsbeginn/massnahmen-bei-unfaellen",
        "detailText": "html/di213-026/di213-026_12_.html",
        "detailTextUri": "praktikumsbeginn/di213-026_12_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-2.8_single_1.svg", "images/cartoons/de/chapter-2.8_single_2.svg", "images/cartoons/de/chapter-2.8_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-2.8_thumb.svg",
        "previewImgTxt": "html/di213-026_12_.html",
        "teaser": "Bei Unfällen Ruhe bewahren, sich selber nicht in Gefahr bringen und Erste Hilfe leisten. ",
        "abstract": "Bei Unfällen im Labor ist sofort die für das Praktikum verantwortliche Person zu benachrichtigen. Ist eine Person verletzt, ist umgehend Erste Hilfe zu leisten. Dabei ist Eigengefährdung zu vermeiden!",
        "profInfos": ["Verhalten im Notfall: retten, Hilfe anfordern, Erste Hilfe leisten!"]
    }]
}, {
    "name": "Hinweise zum praktischen Arbeiten",
    "uri": "hinweise-zum-praktischen-arbeiten",
    "alias": "bgrci_dguvi_di213_026_s3_",
    "subChapters": [{
        "name": "Umgang mit Laborglas",
        "alias": "bgrci_dguvi_di213_026_s3_1_",
        "chapterNo": "3.1",
        "uri": "hinweise-zum-praktischen-arbeiten/umgang-mit-laborglas",
        "detailText": "html/di213-026/di213-026_14_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_14_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.1_single_1.svg", "images/cartoons/de/chapter-3.1_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.1_thumb.svg",
        "previewImgTxt": "html/di213-026_14_.html",
        "teaser": "Umgang mit Glas im Labor hat seine Besonderheiten und muss geübt sein. Erste Ansprechpartner für Tipps und Tricks sind die Assistierenden.",
        "abstract": "Apparaturen im chemischen Laboratorium bestehen überwiegend aus Glas, in der Regel aus Borosilikatglas 3.3. Bei allen Vorteilen, die dieses Material für das chemische Arbeiten bietet, birgt es durch seine mechanische Verletzlichkeit auch Gefahren. Verletzungen an zerbrochenen Glasgefäßen mit z. T. erheblichen Schnittwunden bilden daher die Kategorie der häufigsten Laborunfälle.",
        "profInfos": ["Achtung Verbrennungsgefahr: Heißes Glas sieht genauso aus wie kaltes Glas!"]
    }, {
        "name": "Reinigen von Glasgeräten",
        "alias": "bgrci_dguvi_di213_026_s3_2_",
        "chapterNo": "3.2",
        "uri": "hinweise-zum-praktischen-arbeiten/reinigen-von-glasgeraeten",
        "detailText": "html/di213-026/di213-026_15_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_15_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.2_single_1.svg", "images/cartoons/de/chapter-3.2_single_2.svg", "images/cartoons/de/chapter-3.2_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.2_thumb.svg",
        "previewImgTxt": "html/di213-026_15_.html",
        "teaser": "Das Reinigen von Glasgeräten ist manchmal anstrengend. Nicht immer helfen Chemikalien.",
        "abstract": "Verschmutzte Gefäße, Gegenstände oder Laboreinrichtungen sind sofort zu säubern. Substanzreste dürfen nie in Gefäßen stehen bleiben.",
        "profInfos": []
    }, {
        "name": "Aufbau von Apparaturen",
        "alias": "bgrci_dguvi_di213_026_s3_3_",
        "chapterNo": "3.3",
        "uri": "hinweise-zum-praktischen-arbeiten/aufbau-von-apparaturen",
        "detailText": "html/di213-026/di213-026_16_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_16_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.3_single_1.svg", "images/cartoons/de/chapter-3.3_single_2.svg", "images/cartoons/de/chapter-3.3_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.3_thumb.svg",
        "previewImgTxt": "html/di213-026_16_.html",
        "teaser": "Der spannungsfreie Aufbau einer Apparatur ist gar nicht so einfach. ",
        "abstract": "Der Bruch von Apparaturen während des Experimentierens zählt zu den größten Risiken chemischen Arbeitens, insbesondere weil dadurch gefährliche Substanzen entweichen oder Brände entstehen können. Schon beim Aufbau von Apparaturen müssen daher einige wichtige Sicherheitsgrundsätze beachtet werden.",
        "profInfos": ["Jede Improvisation durch Verwendung ungeeigneter Apparaturteile ist verboten."]
    }, {
        "name": "Beheizen von Apparaturen",
        "alias": "bgrci_dguvi_di213_026_s3_4_",
        "chapterNo": "3.4",
        "uri": "hinweise-zum-praktischen-arbeiten/beheizen-von-apparaturen",
        "detailText": "html/di213-026/di213-026_17_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_17_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.4.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.4_thumb.svg",
        "previewImgTxt": "html/di213-026_17_.html",
        "teaser": "Der Gasbrenner ist nicht mehr Stand der Technik für das Beheizen von Apparaturen. Dieser wurde von Heizbädern und elektrischen Wärmequellen abgelöst.",
        "abstract": "Wenn bei erhöhter Temperatur gearbeitet wird, bilden chemische Reaktionen ein besonderes Gefahrenpotenzial, insbesondere beim Erhitzen entzündbarer Lösemittel. Die folgenden Hinweise sollen helfen, die sich daraus ergebenden Risiken zu vermeiden.",
        "profInfos": ["Grundregel: Vorsichtig erhitzen!", "Silikonölbrände dürfen nie mit Wasser gelöscht werden!"]
    }, {
        "name": "Sicheres Arbeiten mit Kühlern",
        "alias": "bgrci_dguvi_di213_026_s3_5_",
        "chapterNo": "3.5",
        "uri": "hinweise-zum-praktischen-arbeiten/sicheres-arbeiten-mit-kuehlern",
        "detailText": "html/di213-026/di213-026_18_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_18_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.5_single_1.svg", "images/cartoons/de/chapter-3.5_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.5_thumb.svg",
        "previewImgTxt": "html/di213-026_18_.html",
        "teaser": "Kühlwasser fließt im Labor immer bergauf. ",
        "abstract": "Alle beheizten Apparaturen mit entzündbarem Inhalt müssen mit Kühlern zur Rückhaltung flüchtiger, entzündbarer Stoffe versehen sein.",
        "profInfos": []
    }, {
        "name": "Kühlen",
        "alias": "bgrci_dguvi_di213_026_s3_6_",
        "chapterNo": "3.6",
        "uri": "hinweise-zum-praktischen-arbeiten/kuehlen",
        "detailText": "html/di213-026/di213-026_19_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_19_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.6_single_1.svg", "images/cartoons/de/chapter-3.6_single_2.svg", "images/cartoons/de/chapter-3.6_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.6_thumb.svg",
        "previewImgTxt": "html/di213-026_19_.html",
        "teaser": "„Viel hilft viel“ gilt beim Kühlen oftmals nicht.",
        "abstract": "Zum Kühlen verwendet man Eis, Eis-Salz-Mischungen (Natriumchlorid bis ca. –21 °C, Calciumchlorid bis ca. –55 °C), Lösemittel-Trockeneis-Mischungen (bis –78 °C) oder flüssigen Stickstoff (–196 °C). Die als Isoliergefäße häufig verwendeten Dewargefäße sind innen verspiegelte, evakuierte Hohlkörper.",
        "profInfos": []
    }, {
        "name": "Hinweise für Arbeiten mit typischen Laborgeräten",
        "alias": "bgrci_dguvi_di213_026_s3_7_",
        "chapterNo": "3.7",
        "uri": "hinweise-zum-praktischen-arbeiten/hinweise-fuer-arbeiten-mit-typischen-laborgeraeten",
        "detailText": "html/di213-026/di213-026_20_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_20_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.7_single_1.svg", "images/cartoons/de/chapter-3.7_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.7_thumb.svg",
        "previewImgTxt": "html/di213-026_20_.html",
        "teaser": "Gefahren bei Laborgeräten sind nicht immer offensichtlich.",
        "abstract": "Apparate zur Schmelzpunktbestimmung - Diese Apparate dürfen nicht mit konzentrierter Schwefelsäure gefüllt werden. Als Badflüssigkeiten eignen sich z. B. Silikonöle. Zu empfehlen sind Metallblock- oder elektrisch beheizte Flüssigkeits-Schmelzpunktbestimmungsapparate.",
        "profInfos": []
    }, {
        "name": "Arbeiten unter vermindertem Druck",
        "alias": "bgrci_dguvi_di213_026_s3_8_",
        "chapterNo": "3.8",
        "uri": "hinweise-zum-praktischen-arbeiten/arbeiten-unter-vermindertem-druck",
        "detailText": "html/di213-026/di213-026_21_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_21_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.8_single_1.svg", "images/cartoons/de/chapter-3.8_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.8_thumb.svg",
        "previewImgTxt": "html/di213-026_21_.html",
        "teaser": "Implosion und Explosion sind gleich schlimm. ",
        "abstract": "In chemischen Laboratorien werden häufig Experimente in Apparaturen unter vermindertem Druck durchgeführt. Typische Beispiele sind Destillation oder Sublimation im Vakuum, Absaugen von Niederschlägen, Trocknen. Die verwendeten Apparaturen müssen dabei einer erheblichen Belastung durch den Außendruck standhalten.",
        "profInfos": ["Rundkolben dürfen keinesfalls punktuell erhitzt werden!"]
    }, {
        "name": "Tätigkeiten mit Gasen",
        "alias": "bgrci_dguvi_di213_026_s3_9_",
        "chapterNo": "3.9",
        "uri": "hinweise-zum-praktischen-arbeiten/taetigkeiten-mit-gasen",
        "detailText": "html/di213-026/di213-026_22_.html",
        "detailTextUri": "hinweise-zum-praktischen-arbeiten/di213-026_22_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-3.9_single_1.svg", "images/cartoons/de/chapter-3.9_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-3.9_thumb.svg",
        "previewImgTxt": "html/di213-026_22_.html",
        "teaser": "Gasflaschen sind verkappte Raketen. Es fehlt nur das Leitwerk.",
        "abstract": "Gase werden oft als Reaktanden oder Schutzgas in chemischen Reaktionen eingesetzt oder können bei chemischen Reaktionen entstehen. Sie finden auch als Hilfsmittel bei einigen analytischen Verfahren Anwendung.",
        "profInfos": []
    }]
}, {
    "name": "Tätigkeiten mit Chemikalien",
    "uri": "taetigkeiten-mit-chemikalien",
    "alias": "bgrci_dguvi_di213_026_s4_",
    "subChapters": [{
        "name": "Information über Stoffeigenschaften und sicherheitstechnische Kenngrößen",
        "alias": "bgrci_dguvi_di213_026_s4_1_",
        "chapterNo": "4.1",
        "uri": "taetigkeiten-mit-chemikalien/information-ueber-stoffeigenschaften-und-sicherheitstechnische-kenngroessen",
        "detailText": "html/di213-026/di213-026_24_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_24_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.1_single_1.svg", "images/cartoons/de/chapter-4.1_single_2.svg", "images/cartoons/de/chapter-4.1_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.1_thumb.svg",
        "previewImgTxt": "html/di213-026_24_.html",
        "teaser": "Etiketten studieren statt ausprobieren.",
        "abstract": "Wichtige Hinweise auf Flüchtigkeit, Entzündbarkeit, Acidität, Toxizität, Fähigkeit zur Autooxidation oder zum spontanen Zerfall und andere Eigenschaften liefern die Etiketten der Original-Hersteller-Verpackungen von Chemikalien.",
        "profInfos": []
    }, {
        "name": "Allgemeine Vorsichtsmaßnahmen beim Experimentieren",
        "alias": "bgrci_dguvi_di213_026_s4_2_",
        "chapterNo": "4.2",
        "uri": "taetigkeiten-mit-chemikalien/allgemeine-vorsichtsmassnahmen-beim-experimentieren",
        "detailText": "html/di213-026/di213-026_25_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_25_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.2_single_1.svg", "images/cartoons/de/chapter-4.2_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.2_thumb.svg",
        "previewImgTxt": "html/di213-026_25_.html",
        "teaser": "Gesunder Menschenverstand hilft auch im Labor.",
        "abstract": "Alle Experimente müssen sorgfältig geplant und vorbereitet werden. Eine gute Vorbereitung erfolgt anhand einer Betriebsanweisung, in der nicht nur die Reaktionsgleichung und die Versuchsbeschreibung skizziert sind, sondern auch die Kennzeichnung der eingesetzten und synthetisierten Stoffe, Gefahren für Mensch und Umwelt, erforderliche Schutzmaßnahmen und Verhaltensregeln, Verhalten im Gefahrfall, Erste-Hilfe-Maßnahmen und Angaben zur Entsorgung.",
        "profInfos": []
    }, {
        "name": "Richtiges Arbeiten am Abzug",
        "alias": "bgrci_dguvi_di213_026_s4_3_",
        "chapterNo": "4.3",
        "uri": "taetigkeiten-mit-chemikalien/richtiges-arbeiten-am-abzug",
        "detailText": "html/di213-026/di213-026_26_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_26_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.3_single_1.svg", "images/cartoons/de/chapter-4.3_single_2.svg", "images/cartoons/de/chapter-4.3_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.3_thumb.svg",
        "previewImgTxt": "html/di213-026_26_.html",
        "teaser": "Der Abzug schützt – aber nur wenn man ihn richtig verwendet.",
        "abstract": "Der Abzug ist die wichtigste Sicherheitseinrichtung im Labor. Im Abzugsinneren bildet sich in der Regel eine walzenförmige Luftströmung aus, welche die luftgetragenen Gefahrstoffe als Fracht in der Abluft abführt.",
        "profInfos": []
    }, {
        "name": "Hinweise zum Experimentieren mit speziellen Chemikalien und Stoffgruppen",
        "alias": "bgrci_dguvi_di213_026_s4_4_",
        "chapterNo": "4.4",
        "uri": "taetigkeiten-mit-chemikalien/hinweise-zum-experimentieren-mit-speziellen-chemikalien-und-stoffgruppen",
        "detailText": "html/di213-026/di213-026_27_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_27_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.4_single_1.svg", "images/cartoons/de/chapter-4.4_single_2.svg", "images/cartoons/de/chapter-4.4_single_3.svg", "images/cartoons/de/chapter-4.4_single_4.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.4_thumb.svg",
        "previewImgTxt": "html/di213-026_27_.html",
        "teaser": "Manche Chemikalien sind besonders reaktiv.",
        "abstract": "Hinweise für eine beispielhafte Auswahl spezifischer Laborchemikalien werden im Folgenden kurz dargestellt. Tätigkeiten mit den nachfolgend genannten Stoffen erfordern eine gesonderte Unterweisung. Informationen zur Entsorgung der Stoffe oder Gemische sind in Kapitel 4.7 „Entsorgung von Laborabfällen“ enthalten.",
        "profInfos": ["Merke: Erst das Wasser dann die Säure, sonst geschieht das Ungeheure!"]
    }, {
        "name": "Kennzeichnung, Aufbewahrung und Transport",
        "alias": "bgrci_dguvi_di213_026_s4_5_",
        "chapterNo": "4.5",
        "uri": "taetigkeiten-mit-chemikalien/kennzeichnung-aufbewahrung-und-transport",
        "detailText": "html/di213-026/di213-026_29_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_29_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.5_single_1.svg", "images/cartoons/de/chapter-4.5_single_2.svg", "images/cartoons/de/chapter-4.5_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.5_thumb.svg",
        "previewImgTxt": "html/di213-026_29_.html",
        "teaser": "Beim Chemikalientransport gilt: weniger ist mehr.",
        "abstract": "Chemikalien sollten nach Möglichkeit in den Originalgebinden verbleiben, da deren vorschriftsmäßige Etiketten wertvolle Informationen zum sicheren Umgang liefern.",
        "profInfos": []
    }, {
        "name": "Ab- und Umfüllen",
        "alias": "bgrci_dguvi_di213_026_s4_6_",
        "chapterNo": "4.6",
        "uri": "taetigkeiten-mit-chemikalien/ab-und-umfuellen",
        "detailText": "html/di213-026/di213-026_30_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_30_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.6.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.6_thumb.svg",
        "previewImgTxt": "html/di213-026_30_.html",
        "teaser": "Auf die Technik kommt es an.",
        "abstract": "Bei jedem Umfüllen von Chemikalien besteht die Gefahr des Verschüttens, und damit die Gefahr von Hautkontakt oder Kontamination der Kleidung sowie des Einatmens von Dämpfen oder Stäuben und der Bildung zündfähiger Gemische.",
        "profInfos": []
    }, {
        "name": "Entsorgung von Laborabfällen",
        "alias": "bgrci_dguvi_di213_026_s4_7_",
        "chapterNo": "4.7",
        "uri": "taetigkeiten-mit-chemikalien/entsorgung-von-laborabfaellen",
        "detailText": "html/di213-026/di213-026_31_.html",
        "detailTextUri": "taetigkeiten-mit-chemikalien/di213-026_31_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-4.7_single_1.svg", "images/cartoons/de/chapter-4.7_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-4.7_thumb.svg",
        "previewImgTxt": "html/di213-026_31_.html",
        "teaser": "Gleich und Gleich gesellt sich gern. Laborabfälle gehören in die richtigen Behälter. Etiketten lieber zweimal lesen.",
        "abstract": "Dieser Abschnitt kann nur in kurzer Form einige Hinweise zur praktischen Entsorgung im Labor geben und damit anregen, sich in jedem Einzelfall vor Beginn der Arbeiten auch mit den Regeln der Entsorgung an der eigenen Hochschule zu beschäftigen.",
        "profInfos": []
    }]
}, {
    "name": "Brand- und Explosionsgefahren",
    "uri": "brand-und-explosionsgefahren",
    "alias": "bgrci_dguvi_di213_026_s5_",
    "subChapters": [{
        "name": "Entzündbare Flüssigkeiten",
        "alias": "bgrci_dguvi_di213_026_s5_1_",
        "chapterNo": "5.1",
        "uri": "brand-und-explosionsgefahren/entzuendbare-fluessigkeiten",
        "detailText": "html/di213-026/di213-026_33_.html",
        "detailTextUri": "brand-und-explosionsgefahren/di213-026_33_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-5.1_single_1.svg", "images/cartoons/de/chapter-5.1_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-5.1_thumb.svg",
        "previewImgTxt": "html/di213-026_33_.html",
        "teaser": "Die Dämpfe sind das Gefährliche.",
        "abstract": "Viele Flüssigkeiten, mit denen im Labor umgegangen wird, sind entzündbar. Ihre Dämpfe bilden mit Luft in für einzelne Flüssigkeiten spezifischen Konzentrationsbereichen explosionsfähige Gemische.",
        "profInfos": []
    }, {
        "name": "Selbstentzündliche Stoffe",
        "alias": "bgrci_dguvi_di213_026_s5_2_",
        "chapterNo": "5.2",
        "uri": "brand-und-explosionsgefahren/selbstentzuendliche-stoffe",
        "detailText": "html/di213-026/di213-026_34_.html",
        "detailTextUri": "brand-und-explosionsgefahren/di213-026_34_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-5.2_single_1.svg", "images/cartoons/de/chapter-5.2_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-5.2_thumb.svg",
        "previewImgTxt": "html/di213-026_34_.html",
        "teaser": "Brandgefährlich und manchmal sogar explosiv.",
        "abstract": "Eine Quelle für Laborbrände oder auch Explosionen sind Stoffe, die sich bei Zutritt von Luft oder Wasser spontan entzünden können. In der Laborpraxis sind dies vor allem die Alkalimetalle und ihre Hydride sowie (gebrauchte und getrocknete) Hydrierkatalysatoren.",
        "profInfos": []
    }, {
        "name": "Explosive Stoffe oder Gemische",
        "alias": "bgrci_dguvi_di213_026_s5_3_",
        "chapterNo": "5.3",
        "uri": "brand-und-explosionsgefahren/explosive-stoffe-oder-gemische",
        "detailText": "html/di213-026/di213-026_35_.html",
        "detailTextUri": "brand-und-explosionsgefahren/di213-026_35_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-5.3_single_1.svg", "images/cartoons/de/chapter-5.3_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-5.3_thumb.svg",
        "previewImgTxt": "html/di213-026_35_.html",
        "teaser": "Auf die Mischung kommt es an.",
        "abstract": "Als explosiv bezeichnet man Stoffe oder Gemische, die bei Einwirkung von thermischer Energie (Wärme, Flammen) oder mechanischer Energie (Reibung, Schlag) spontan heftig reagieren, sodass eine hohe Energiefreisetzung und ein hoher Druckaufbau zu sehr schneller Ausbreitung von Druckwellen führen.",
        "profInfos": []
    }, {
        "name": "Exotherme Reaktionen",
        "alias": "bgrci_dguvi_di213_026_s5_4_",
        "chapterNo": "5.4",
        "uri": "brand-und-explosionsgefahren/exotherme-reaktionen",
        "detailText": "html/di213-026/di213-026_36_.html",
        "detailTextUri": "brand-und-explosionsgefahren/di213-026_36_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-5.4_single_1.svg", "images/cartoons/de/chapter-5.4_single_2.svg", "images/cartoons/de/chapter-5.4_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-5.4_thumb.svg",
        "previewImgTxt": "html/di213-026_36_.html",
        "teaser": "1 + 1 = 3",
        "abstract": "Viele chemische Reaktionen sind exotherm. Wird die gebildete Wärme nicht oder nicht ausreichend abgeführt, führt dies zur Selbsterhitzung der Reaktionsmischung und damit zur Beschleunigung des Reaktionsverlaufes („Durchgehen“ der Reaktion, in der Literatur auch als „runaway reactions“ bezeichnet).",
        "profInfos": []
    }, {
        "name": "Zersetzungsreaktionen",
        "alias": "bgrci_dguvi_di213_026_s5_5_",
        "chapterNo": "5.5",
        "uri": "brand-und-explosionsgefahren/zersetzungsreaktionen",
        "detailText": "html/di213-026/di213-026_37_.html",
        "detailTextUri": "brand-und-explosionsgefahren/di213-026_37_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-5.5_single_1.svg", "images/cartoons/de/chapter-5.5_single_2.svg", "images/cartoons/de/chapter-5.5_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-5.5_thumb.svg",
        "previewImgTxt": "html/di213-026_37_.html",
        "teaser": "Für die Mimosen unter den Chemikalien.",
        "abstract": "Viele Stoffe oder Gemische erweisen sich bei thermischer oder mechanischer Beanspruchung als instabil. Sie zersetzen sich unter Umständen spontan mit hoher Energiefreisetzung, sodass die Zersetzung einen explosionsartigen Verlauf nehmen kann. Dabei können auch toxische Stoffe entstehen und freigesetzt werden.",
        "profInfos": []
    }]
}, {
    "name": "Toxikologie",
    "uri": "toxikologie",
    "alias": "bgrci_dguvi_di213_026_s6_",
    "subChapters": [{
        "name": "Gefahren bei Tätigkeiten mit gesundheitsschädigenden Stoffen",
        "alias": "bgrci_dguvi_di213_026_s6_1_",
        "chapterNo": "6.1",
        "uri": "toxikologie/gefahren-bei-taetigkeiten-mit-gesundheitsschaedigenden-stoffen",
        "detailText": "html/di213-026/di213-026_39_.html",
        "detailTextUri": "toxikologie/di213-026_39_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-6.1_single_1.svg", "images/cartoons/de/chapter-6.1_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-6.1_thumb.svg",
        "previewImgTxt": "html/di213-026_39_.html",
        "teaser": "Schon Paracelsus wusste: Die Dosis macht das Gift.",
        "abstract": "Das Fachgebiet Toxikologie („Giftkunde“) befasst sich mit den gesundheitlichen Auswirkungen von Giftstoffen auf Organismen. Aufgabe der Toxikologie ist es, Zusammenhänge zwischen der Belastung durch Giftstoffe und den dadurch verursachten Schadwirkungen zu erkennen, Wirkmechanismen aufzuklären und idealerweise auch mögliche Gefährdungen vorherzusagen.",
        "profInfos": []
    }, {
        "name": "Toxikokinetik",
        "alias": "bgrci_dguvi_di213_026_s6_2_",
        "chapterNo": "6.2",
        "uri": "toxikologie/toxikokinetik",
        "detailText": "html/di213-026/di213-026_40_.html",
        "detailTextUri": "toxikologie/di213-026_40_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-6.2_single_1.svg", "images/cartoons/de/chapter-6.2_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-6.2_thumb.svg",
        "previewImgTxt": "html/di213-026_40_.html",
        "teaser": "Gift nimmt viele Wege.",
        "abstract": "Die Wechselwirkungen eines gesundheitsschädigenden Stoffes mit dem Organismus lassen sich verschiedenen Teilschritten zuordnen: Resorption, Distribution, Metabolismus, Elimination",
        "profInfos": []
    }, {
        "name": "Wirkmechanismen von krebserzeugenden Stoffen",
        "alias": "bgrci_dguvi_di213_026_s6_3_",
        "chapterNo": "6.3",
        "uri": "toxikologie/wirkmechanismen-von-krebserzeugenden-stoffen",
        "detailText": "html/di213-026/di213-026_45_.html",
        "detailTextUri": "toxikologie/di213-026_45_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-6.3_single_1.svg", "images/cartoons/de/chapter-6.3_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-6.3_thumb.svg",
        "previewImgTxt": "html/di213-026_45_.html",
        "teaser": "Die verborgene Gefahr.",
        "abstract": "Krebserzeugende Stoffe (Kanzerogene) greifen unmittelbar oder mittelbar in den Zellstoffwechsel ein, so dass normale Zellen in Krebszellen umgewandelt werden.",
        "profInfos": []
    }, {
        "name": "Beurteilung von Gefährdungen durch gesundheitsschädigende Stoffe",
        "alias": "bgrci_dguvi_di213_026_s6_4_",
        "chapterNo": "6.4",
        "uri": "toxikologie/beurteilung-von-gefaehrdungen-durch-gesundheitsschaedigende-stoffe",
        "detailText": "html/di213-026/di213-026_46_.html",
        "detailTextUri": "toxikologie/di213-026_46_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-6.4_single_1.svg", "images/cartoons/de/chapter-6.4_single_2.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-6.4_thumb.svg",
        "previewImgTxt": "html/di213-026_46_.html",
        "teaser": "Gefahren in Grenzen halten – Grenzwerte schützen",
        "abstract": "Hilfreich bei der Beurteilung von Gefährdungen durch gesundheitsschädigende Stoffe ist es, Höchstwerte der Belastung zu kennen, bei deren Unterschreitung auch bei länger andauernder Einwirkung keine schädlichen Auswirkungen auf die Gesundheit zu erwarten sind oder das Risiko zu erkranken, sehr gering ist. Als derartige Höchstwerte existieren unterschiedliche Beurteilungsmaßstäbe.",
        "profInfos": []
    }, {
        "name": "Typische Gefahrstoffe",
        "alias": "bgrci_dguvi_di213_026_s6_5_",
        "chapterNo": "6.5",
        "uri": "toxikologie/typische-gefahrstoffe",
        "detailText": "html/di213-026/di213-026_50_.html",
        "detailTextUri": "toxikologie/di213-026_50_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-6.5_single_1.svg", "images/cartoons/de/chapter-6.5_single_2.svg", "images/cartoons/de/chapter-6.5_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-6.5_thumb.svg",
        "previewImgTxt": "html/di213-026_50_.html",
        "teaser": "Die üblichen Verdächtigen – Steckbriefe im Überblick.",
        "abstract": "Ätz- und Reizgase reizen die Haut und die Schleimhäute, insbesondere die der Atemwege und der Augen. Ihr Auftreten macht sich daher durch Hustenreiz, Stechen in Nase und Rachen sowie Brennen und Tränen der Augen bemerkbar. Bei bestimmten Gasen können aber die ersten Anzeichen einer Vergiftung sehr schwach sein und es kann dennoch nach mehreren Stunden zu einer schweren Schädigung der Lunge kommen (Lungenödem), zuweilen mit tödlichem Verlauf.",
        "profInfos": ["Ein Liter flüssiger Stickstoff ergibt ca. 700 Liter gasförmigen Stickstoff.", "Ein Kilogramm Trockeneis bildet beim Verdampfen 541 Liter gasförmiges Kohlenstoffdioxid! Deshalb darf Trockeneis nur in belüfteten Räumen gelagert werden."]
    }, {
        "name": "Mutterschutz und Gefahrstoffe",
        "alias": "bgrci_dguvi_di213_026_s6_6_",
        "chapterNo": "6.6",
        "uri": "toxikologie/mutterschutz-und-gefahrstoffe",
        "detailText": "html/di213-026/di213-026_58_.html",
        "detailTextUri": "toxikologie/di213-026_58_.html",
        "cartoonSingle": ["images/cartoons/de/chapter-6.6_single_1.svg", "images/cartoons/de/chapter-6.6_single_2.svg", "images/cartoons/de/chapter-6.6_single_3.svg"],
        "cartoonThumb": "images/cartoons/thumbs/chapter-6.6_thumb.svg",
        "previewImgTxt": "html/di213-026_58_.html",
        "teaser": "Besondere Situation – Besonderer Schutz.",
        "abstract": "Das Mutterschutzgesetz gilt nicht nur für Beschäftigte sondern auch für Studentinnen. Unter anderem definiert das Mutterschutzgesetz Tätigkeiten, bei denen schwangere und stillende Frauen unverantwortbaren Gefährdungen ausgesetzt sind.",
        "profInfos": []
    }]
}, {
    "name": "Anhang 1: Informationsquellen",
    "uri": "anhang-informationsquellen",
    "alias": "bgrci_dguvi_di213_026_ag1_",
    "path": "html/di213-026/di213-026_59_.html"
}, {
    "name": "Anhang 2: Rechtsgrundlagen und Informationen",
    "uri": "anhang-rechtsgrundlagen-und-informationen",
    "alias": "bgrci_dguvi_di213_026_ag2_",
    "path": "html/di213-026/di213-026_60_.html"
}, {
    "name": "Anhang 3: Einstufung und Kennzeichnung von Chemikalien",
    "uri": "anhang-einstufung-und-kennzeichnung-von-chemikalien",
    "alias": "bgrci_dguvi_di213_026_ag3_",
    "path": "html/di213-026/di213-026_61_.html"
}, {
    "name": "Anhang 4: Aufbau der Gefahrenhinweise und der Sicherheitshinweise",
    "uri": "anhang-aufbau-der-gefahrenhinweise-und-der-sicherheitshinweise",
    "alias": "bgrci_dguvi_di213_026_ag4_",
    "path": "html/di213-026/di213-026_65_.html"
}, {
    "name": "Anhang 5: Entzündbare Stoffe und Brandklassen",
    "uri": "anhang-entzuendbare-stoffe-und-brandklassen",
    "alias": "bgrci_dguvi_di213_026_ag5_",
    "path": "html/di213-026/di213-026_70_.html"
}, {
    "name": "Anhang 6: Arbeiten mit elektrischen Anlagen und Betriebsmitteln",
    "uri": "anhang-arbeiten-mit-elektrischen-anlagen-und-betriebsmitteln",
    "alias": "bgrci_dguvi_di213_026_ag6_",
    "path": "html/di213-026/di213-026_71_.html"
}, {
    "name": "Anhang 7: Kennzeichnung von Chemikalienschutzhandschuhen",
    "uri": "anhang-kennzeichnung-von-chemikalienschutzhandschuhen",
    "alias": "bgrci_dguvi_di213_026_ag7_",
    "path": "html/di213-026/di213-026_72_.html"
}, {
    "name": "Bildverzeichnis",
    "uri": "bildverzeichnis",
    "alias": "bgrci_dguvi_di213_026_73_",
    "path": "html/di213-026/di213-026_73_.html"
}, {
    "name": "Was Sie für Notfälle wissen sollten!",
    "uri": "was-sie-fuer-notfaelle-wissen-sollten!",
    "alias": "bgrci_dguvi_di213_026_g63_",
    "path": "html/di213-026/di213-026_74_.html"
}]
