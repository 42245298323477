import React from "react";
import PropTypes from "prop-types";
import { FaTimesCircle } from "react-icons/fa";
import "./CloseButton.sass";
import { Trans, useTranslation } from "react-i18next";

export default function CloseButton({
  className = "",
  action = undefined,
  tabIndex = 0,
}) {
  const { t } = useTranslation();
  return (
    <button
      className={"close-btn " + className}
      onClick={action}
      title={t("ansicht")}
      tabIndex={tabIndex}
    >
      <FaTimesCircle />
      <span className="sr-only">
        <Trans>menu</Trans>
      </span>
    </button>
  );
}

CloseButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func,
  tabIndex: PropTypes.number,
};
