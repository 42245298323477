import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.sass";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer" className="container-lg-fluid">
      <Container fluid="container-lg" className="footer-links">
        <Link to="/impressum" className="footer-link">
          <Trans>impressum</Trans>
        </Link>
        <Link to="/datenschutz" className="footer-link">
          <Trans>datenschutz</Trans>
        </Link>
        <Link to="/barrierefreiheit" className="footer-link">
          {" "}
          <Trans>barrierefreiheit</Trans>
        </Link>
      </Container>
    </footer>
  );
};

export default Footer;
