import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    // else scroll to id
    else {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [pathname, hash]);

  return null;
};

export default ScrollToTop;
